import { Link } from 'gatsby'
import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import { bannedStatesString } from '../banned-states'
import { CARDS } from '../internal-links'

import './TermsAndConditions.scss'

const TermsAndConditions: React.FC = () => {
  return (
    <div id="terms-and-conditions">
      <Container>
        <h1>
          <span className="underline">Terms</span> Of Use
        </h1>
      </Container>
      <ol className="decimal-counter o1">
        <div style={{ padding: '40px 0' }}>
          <Container>
            <li>
              <h2>General</h2>
              <ol className="decimal-counter o2">
                <li>
                  Welcome to Mega, an online real-money gaming platform
                  currently available through our website
                  [https://www.getmega.com], and mobile applications
                  (collectively, “<strong>App</strong>”). The App is owned and
                  operated by Megashots Internet Private Limited, whose
                  registered office is located at #25, 8<sup>th</sup> Main, 2
                  <sup>nd</sup> Floor, Vasanthnagar, Bangalore, Karnataka 560
                  052.
                </li>
                <li>
                  The App enables you (the “<strong>Player</strong>”) to play
                  various games of skill (“<strong>Games</strong>”) in various
                  formats against other Players in online contests (“
                  <strong>Contests</strong>”) under different sets of game rules
                  (“<strong>Formats</strong>”) by paying an entry fee (“
                  <strong>Entry Fee</strong>”) to win prizes (“
                  <strong>Winnings</strong>”).
                </li>
                <li>
                  For the purpose of these terms of use (“<strong>Terms</strong>
                  ”), “<strong>we</strong>”, “<strong>us</strong>”, “
                  <strong>our</strong>” and “<strong>Mega</strong>” means
                  Megashots Internet Private Limited and “<strong>you</strong>”,
                  “<strong>your</strong>” or “<strong>Player</strong>” means any
                  person who accesses or uses the App or the Games.
                </li>
                <li>
                  By accessing or using this App, you agree to be bound by these
                  Terms and our other policies made available on the App,
                  including but not limited to the Privacy Policy (“
                  <strong>Policies</strong>”). Please read the Terms and our
                  other Policies before doing so. If you do not agree to these
                  Terms or any of our Policies, please exit the App and do not
                  use or access the App or play any of the Games.
                </li>
                <li>
                  By accepting these Terms, you confirm that you have the
                  capacity to enter into a legally binding contract under
                  applicable law, including, in particular, the Indian Contract
                  Act, 1872.
                </li>
                <li>
                  Our App and the Games are designed to be used only in certain
                  states in India. If you access the App from locations outside
                  India, you do so at your own risk and you are solely liable
                  for compliance with applicable local laws. You are not
                  permitted to use the App in certain states in India. Further,
                  the usage of specific Games may be restricted in other states.
                  Please read these Terms and the relevant Game&apos;s terms (if
                  any) carefully before playing.
                </li>
                <li>
                  Access to user accounts will be restricted for individuals
                  attempting to log in from banned states, and if they attempt
                  to use masked GPS or fake IP addresses, or VPN to bypass the
                  restrictions, their accounts will be permanently banned.
                </li>
                <li>
                  We reserve the right to amend all or any of the Terms at our
                  sole discretion without any prior notice. Your continued use
                  of the App or the Games following any such amendment indicates
                  your acceptance of such amendment.
                </li>
                <li>
                  Subject to the above, and your compliance with these Terms, we
                  grant you a non-exclusive, revocable, limited privilege to
                  access and use the App only for your personal, non-commercial
                  use.
                </li>
              </ol>
            </li>
            <li>
              <h2>Advanced KYC</h2>
              <ol className='decimal-counter o2'>
                <li>Ensuring compliance with legal and regulatory requirements.</li>
                <li>Verifying the identities of users to prevent fraud, money 
                  laundering, and other illicit activities.</li>
                <li>Protecting the security and integrity of the platform and its 
                  users.</li>
                <li>Establishing clear guidelines for identity verification processes,
                   including the use of advanced and video-based methods.</li>
                <li>Communicating to users the expectations and requirements regarding
                   identity verification when using the website's services.</li>
              </ol>
              <h3>Note: During the process accounts will be on hold.</h3>
            </li>
          </Container>
        </div>

        <div className="white">
          <Container>
            <li>
              <h2>A Quick Tour</h2>
              <ol className="decimal-counter o2">
                <li>Here is a summary of how you can use the App.</li>
                <p>
                  Download Mega App &#8594; Install Mega App &#8594; Accept
                  these Terms &#8594; Create a Player Profile &#8594; Remit
                  funds which will reflect in your Remittance Portfolio&#8594;
                  Choose a Game &#8594; Choose a Contest &#8594; Meet the
                  Winning Criteria &#8594; Win and Receive Winnings &#8594; Your
                  Winnings will reflect in your Winnings Portfolio &#8594; Claim
                  your Winnings including requesting a transfer of your monetary
                  Winnings reflecting in your Winnings Portfolio to your own
                  bank account or your own wallet, in Indian Rupees.
                </p>
              </ol>
            </li>
            <li>
              <h2>Our Services</h2>
              <ol className="decimal-counter o2">
                <li>
                  The App enables Players to play various Games and win prizes
                  in the form of Winnings.
                </li>
                <li>
                  The App offers the Games in the following broad categories:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      <em>
                        <Link to={CARDS}>Card Games</Link>
                      </em>
                      <p>
                        These Games are played using the standard 52-deck of
                        French playing cards;
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </Container>
        </div>

        <div style={{ padding: '40px 0 80' }}>
          <Container>
            <li>
              <h2>Games of Skill</h2>
              <ol className="decimal-counter o2">
                <li>
                  All Games offered on the App are games of skill and are made
                  available on the basis that they do not constitute betting or
                  gambling under the relevant applicable laws. While some Games
                  on our platform are available on a ‘free-to-play’ basis, most
                  Games have an Entry Fee. Some states in India prohibit playing
                  any games for money including where such games are games of
                  skill. While we make efforts to ensure that the relevant Games
                  are not available in states where they are restricted, you
                  remain solely responsible for ensuring that using the App or
                  playing a Game is legally permissible in every location where
                  you do so and we shall not be liable in any manner whatsoever
                  for any claims or losses arising out of your violation of
                  applicable law. You are advised, in particular, to provide
                  accurate information in relation to your location, and not use
                  means including virtual private networks, or other tools to
                  circumvent any restrictions or mechanisms on the App.
                </li>
                <li>
                  If you are a resident of, or are travelling to,{' '}
                  {bannedStatesString} or outside India, please do not access
                  the App, create a Player Profile or play any of the Games.
                </li>
              </ol>
            </li>

            <li>
              <h2>Player Profile</h2>
              <ol className="decimal-counter o2">
                <li>
                  Upon installing the App, you will be prompted to register on
                  the App by creating a user account (“
                  <strong>Player Profile</strong>”). You will be required to
                  create the Player Profile by linking the Player Profile with
                  your account on a third-party platform such as Facebook. We
                  will receive your full name as you have entered on such a
                  third party application, email address, photograph and other
                  public information that you have authorized such third party
                  platforms to share, for the purposes of displaying the same on
                  the App.
                </li>
                <li>
                  Additionally, you will be required to enter your mobile number
                  and verify the same through an OTP sent by us to you for these
                  purposes. You are also required to update your location on
                  your Player Profile.
                </li>
                <li>
                  You may register only 1 (one) Player Profile on the App. We
                  may cancel or terminate your Player Profile if we have reasons
                  to suspect that you have concurrently registered or are in
                  control of 2 (two) or more Player Profiles.
                </li>
                <li>
                  Further, we have the right to reject your application for
                  registration for any reason.
                </li>
                <li>
                  Player Profiles are personal, confidential and not
                  transferable except for the instances as and when required by
                  us to use your Player Profile for any advertising and other
                  related purposes.
                </li>
                <li>
                  You shall be solely responsible for the confidentiality of the
                  login credentials used by you to access the App, not disclose
                  the same to anybody and take all possible care to prevent
                  discovery of the login credentials by any person. In any
                  event, we are not liable or responsible for any unauthorized
                  access to your Player Profile. You will immediately notify us
                  of any unauthorized use of your Player Profile or any other
                  breach of these Terms or security of the App, known to you.
                </li>
                <li>
                  Please note that we shall be entitled to presume that any
                  person who logs in with your login credentials is you. You
                  will therefore be responsible for any actions taken using your
                  Player Profile.
                </li>
              </ol>
            </li>

            <li>
              <h2>Portfolio</h2>
              <ol className="decimal-counter o2">
                <li>
                  When a Player Profile is created, it will display 2 (two) sets
                  of portfolios, associated with it:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      <strong>Remittance Portfolio</strong>. Your remittance
                      portfolio (“<strong>Remittance Portfolio</strong>”) will
                      reflect the value in Indian Rupees (“<strong>INR</strong>
                      ”) remitted by you for playing Games on the App and
                      additional amounts received by you from signups, referral
                      bonuses and promotional offers that may be offered on the
                      App.
                    </li>
                    <li>
                      <strong>Winnings Portfolio</strong>. The winnings
                      portfolio (“<strong>Winnings Portfolio</strong>”) will
                      contain the amount of money won by you as Winnings in INR.
                    </li>
                  </ol>
                </li>
                <li>
                  Subject to the Terms, all gems and packs purchased by you
                  prior to March 2, 2020 will stand converted into amounts
                  reflected in your Remittance Portfolio. All free gems received
                  by you prior to March 2, 2020 will be extinguished. By way of
                  illustration, if you had 10 (ten) gems available in your gem
                  balance or equivalent packs of which 9 (nine) gems were
                  purchased and 1 (one) gem was received free, then an amount of
                  INR 9 (Indian Rupees Nine) will be reflected in your
                  Remittance Portfolio.
                </li>
                <li>
                  The balance reflected in your Remittance Portfolio may only be
                  applied towards playing Games on the App. The value available
                  in INR in the Remittance Portfolio cannot be withdrawn into
                  your Designated Account (as defined below) or transferred into
                  your Winnings Portfolio, subject to terms set out in paragraph
                  11.5 below.
                </li>
                <li>
                  Your Remittance and Winnings Portfolios are not wallets or
                  accounts and contain no real money:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      The Remittance Portfolio only reflects the amount remitted
                      by you to enter a Contest or any additional amounts
                      received by you from signups, referral bonuses and
                      promotional offers that may be offered on the App.
                    </li>
                    <li>
                      Similarly, the Winnings Portfolio only reflects the amount
                      which we offer to pay you in the manner specified in these
                      Terms upon a claim made by you and upon fulfilment of KYC
                      requirements and other conditions specified in these
                      Terms, subject to our satisfaction. It is hereby clarified
                      that, the amount reflected in the Winnings Portfolio does
                      not belong to you until you have made a claim to withdraw
                      that amount to your Designated Account and you have
                      fulfilled other conditions mentioned in these Terms.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <h2>Playing Games AND CONTESTS</h2>
              <ol className="decimal-counter o2">
                <li>
                  Once your Player Profile is created, and your Remittance
                  Portfolio and Winnings Portfolio are set up, you can play
                  Games by entering one or more Contests. Each Contest consists
                  of a Game which can be played in a particular Format.
                </li>
                <li>
                  We may make Contests available in various formats either on a
                  continuous basis, as well as in one-off, or periodic, events
                  or tournaments. While each Contest will have specific rules
                  for participation and gameplay, the following are some general
                  rules applicable to typical Formats on the App. Please make
                  sure that you read, understand and carefully review these
                  rules, as well as any additional Contest specific rules,
                  before you enter any Contest.
                  <ol className="decimal-counter o3">
                    <li>
                      We may cancel and/or withdraw any Contests on the App or
                      restrict your access with the Contest or the entire App,
                      without prior, at our sole discretion, if it comes to our
                      notice that conduct of such Contest is prohibited or
                      restricted in any jurisdiction (including any Indian
                      state).
                    </li>
                    <li>
                      We may operate multiple Contests for the same Game under
                      different Formats and gameplay rules, or for different
                      durations. You have the option to participate in as many
                      Contests as you would like to.
                    </li>
                    <li>
                      When you decide to participate in a Contest and play the
                      corresponding Game, we will match you with one or more
                      Players (depending on the nature of the Game)
                      participating in the same Contest. You may be unable to
                      participate in a Contest, or a Contest may not commence,
                      if sufficient number of Players are not available, or if
                      we are unable to match you with a Player during the
                      Contest at the time you choose to play the Game.
                    </li>
                    <li>
                      Contests typically take place in point formats (“
                      <strong>Instant Contest</strong>”) or cash formats (“
                      <strong>Cash Contest</strong>”).
                    </li>
                  </ol>
                </li>
                <li>
                  <h3>Instant Contest</h3>
                  <ol className="decimal-counter o3">
                    <li>
                      To enter an Instant Contest you will have to pay the
                      specified entry fee (“<strong>Entry Fee</strong>”) from
                      amounts reflected in your Remittance Portfolio or Winnings
                      Portfolio. You can use amounts from the latter only after
                      exhausting the former. Some Instant Contests may also
                      allow you to join using a Pass (as explained below).
                    </li>
                    <li>
                      You may be able to play a Game multiple times (each a “
                      <strong>Game Session</strong>”) in the same Contest until
                      it concludes and may score points for each Game Session.
                    </li>
                    <li>
                      For each Contest, your highest point score across Game
                      Sessions for such Contest will be logged as your “
                      <strong>High Score</strong>” for the Contest.
                    </li>
                    <li>
                      Your High Score as well as every other Player’s High Score
                      will be displayed after each Game Session. Whether your
                      High Score will result in you being eligible to receive
                      Winnings will depend on the rules of the particular
                      Contest.
                    </li>
                    <li>
                      Each Contest has different winning criteria which will be
                      notified to you before you join the Contest. Each win has
                      corresponding and different Winnings, which are also
                      specified at the time of joining the Contest.
                    </li>
                    <li>
                      The wins and corresponding Winnings will be determined and
                      declared for each Contest only after the Contest
                      concludes.
                    </li>
                    <li>
                      The Winnings to which you are entitled based on the rules
                      of the Contest and applicable Mega policies will be
                      reflected in your Winnings Portfolio (as defined below) as
                      soon as reasonably possible after the end of the relevant
                      Contest.
                    </li>
                  </ol>
                </li>
                <li>
                  <h3>CASH CONTEST</h3>
                  <ol className="decimal-counter o3">
                    <li>
                      When you choose to enter a Cash Contest you will be able
                      to view ongoing Games on various tables. You may select a
                      table, subject to the rules of the specific Game and rules
                      for Minimum and Maximum Players.
                    </li>
                    <li>
                      You will need to deposit a specified value (denominated in
                      INR) in your Remittance Portfolio, to participate in the
                      Contest (“<strong>Buy-in</strong>”). You can deposit from
                      amounts reflected in your Remittance Portfolio or Winnings
                      Portfolio, you can however, use amounts from the latter
                      only after exhausting the former.
                    </li>
                    <li>
                      The value of the Buy-in may vary within the range
                      specified for the Contest and is typically between three
                      to four times the maximum loss possible on a table in the
                      Game. The Buy-in may be revised from time to time, based
                      on multiple factors such as nature and format of the Game
                      in the corresponding Contest.
                    </li>
                    <li>
                      After You have remitted the Minimum Buy-in, you will be
                      given an initial stack of chips or amounts expressed in
                      Indian Rupees (“<strong>Player Stack</strong>”) to play
                      with in the Contest. The value of the Player Stack may be
                      expressed as chips or amounts expressed in Indian Rupees,
                      depending on the nature of the Game.
                    </li>
                    <li>
                      You will be permitted to add value to your Player Stack (“
                      <strong>Top-Up</strong>”). Top-Up will only be permitted
                      between rounds of a game in the Contest. There may also be
                      additional restrictions on the value or frequency of the
                      Top-Up from time to time, at our sole discretion. These
                      rules will be notified to you before you enter the
                      Contest.
                    </li>
                    <li>
                      You are permitted to be in ‘<strong>Away Mode</strong>’ in
                      Cash Contests for a limited period of time, as specified
                      in the rules of the Contest. If you do not return and
                      re-enter playing by pressing the ‘I am Back’ button within
                      the specified time, you will be automatically be placed in
                      the Sit-Out mode.
                    </li>
                    <li>
                      In some Contests, you may be permitted to sit out of a few
                      rounds during the Contest, without losing any value from
                      the Player Stack or position on the table (“Sit-Out”). You
                      can Sit-Out by pressing the ‘Sit-Out’ option is accessible
                      via the top-left hamburger menu on the App. The maximum
                      permissible time to be in ‘<strong>Sit-Out</strong>’ mode
                      during a Contest and the number of times you can opt to be
                      in ‘<strong>Sit-Out</strong>’ mode will be specified in
                      the rules of the Contest.
                    </li>
                    <li>
                      Once you opt to Sit-Out, a timer will start, and unless
                      you press ‘<strong>Sit Here</strong>’ option on the App,
                      before the timer winds down to zero, you will be
                      eliminated from the Contest and the value of your Player
                      Stack will be reflected in the Winnings Portfolio, subject
                      to deductions or withholding as set out in these Terms.
                    </li>
                    <li>
                      You are permitted to switch tables{' '}
                      <strong>within the same Contest</strong>
                      after the completion of a round (“
                      <strong>Switch Tables</strong>”). You are not permitted to
                      Switch Tables during a round.
                    </li>
                    <li>
                      Switching Tables is subject to the Maximum and Minimum
                      Player rules. You cannot Switch Tables if you constitute
                      the Minimum Players on a table and cannot sit at a table
                      which already has the Maximum Players permissible.
                    </li>
                    <li>
                      You can choose to exit a Contest at any time. Upon your
                      exit, the value of your Player Stack will be transferred
                      and reflect in your Winnings Portfolio.
                    </li>
                    <li>
                      For each Contest in the Cash Contest Format, we take a
                      small amount from the pot on a table, as a fee from the
                      winner of the Contest (“<strong>Rake</strong>”). The value
                      of the Rake is subject to the nature of the game.
                      Presently the Rake for poker is between 2.5% (two point
                      five percent) to 5% (five percent), for casual games is
                      between 10% (ten percent) to 15% (fifteen percent), for
                      trivia games is 10% (ten percent) and for rummy is between
                      10% (ten percent) to 15% (fifteen percent). The amount of
                      Rake for each Contest will be specified in the rules of
                      the Contest.
                    </li>
                    <li>
                      Each Contest will specify the rules for a minimum and
                      maximum number of players permitted on a table (“
                      <strong>Minimum and Maximum Players</strong>”). The rules
                      will vary in each game. For example, in a game of poker we
                      permit a maximum of 8 (right players) and for a game of
                      rummy we permit a maximum of 6 (six) players.
                    </li>
                    <li>
                      The App currently offers the Game, Poker, as a Cash
                      Contest. For, this Game, in addition to the rules of the
                      Contest available here, you should note that:
                      <ol style={{ listStyleType: 'lower-roman' }}>
                        <li>
                          The big blind and small blind will remain constant on
                          each table and will not change during the time of game
                          play; and
                        </li>
                        <li>
                          Bet will mean the bet placed by you based on your
                          assessment of the round and your overall position in
                          the Contest. The Game will move forward only when all
                          the other Players on the table have placed the bet.
                          Each Player is required to either match or raise the
                          bet, otherwise the Player is be required to fold and
                          lose the particular round of the Game
                        </li>
                      </ol>
                    </li>
                    <li>
                      For other Games such as Rummy, Trivia or Carrom in the
                      Cash Contests the Player Stack will be represented as
                      points. In addition to the rules of the Game, you should
                      note that:
                      <ol style={{ listStyleType: 'lower-roman' }}>
                        <li>
                          The Contests will be played out as a ‘race to zero’,
                          meaning, the objective in each of such Contests will
                          be to reduce the value of the Player Stack to zero.
                          This can be achieved by making valid sets or
                          sequences, answering questions correctly or potting
                          the coins, in accordance with the rules of the Game.
                        </li>
                        <li>
                          The Winner will be the first player on the table to
                          reach 0 (zero), the winnings are calculated as the
                          value of the total points on the table multiplied with
                          the loss of value in the Player Stack of all other
                          Players on the table, subject to deductions as per
                          Mega Policies. By way of illustration:
                        </li>
                      </ol>
                      <p>
                        For example, in a 4 player Trivia contest with 1 point =
                        Rs. 0.1 conversion, the players’ scores after 4
                        questions are:
                      </p>
                      <p>
                        <em>Player A: 0 points, </em>
                      </p>
                      <p>
                        <em>Player B: 10 points,</em>
                      </p>
                      <p>
                        <em>Player C: 20 points, </em>
                      </p>
                      <p>
                        <em>Player D: 40 points. </em>
                      </p>
                      <p>
                        <em>
                          At this point, as Player A has reached 0 score, the
                          round would end and winnings will be distributed based
                          on the point to INR conversion. Each player&#8217;s
                          winnings and loses will be following manner,
                        </em>
                      </p>
                      <p>
                        <em>Player A: wins Rs.7*(1-Rake) </em>
                      </p>
                      <p>
                        <em>Player B: loses Rs.1 (10points x Rs.0.1)</em>
                      </p>
                      <p>
                        <em>Player C: loses Rs.2 (20points x Rs.0.1)</em>
                      </p>
                      <p>
                        <em>Player D: loses Rs.4 (40points x Rs.0.1) </em>
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <h2>Passes</h2>
              <ol className="decimal-counter o2">
                <li>
                  We love having you on-board the App and to get you started, we
                  may provide you with passes (“<strong>Passes</strong>”) for
                  one or more Games, or for one or more category of Games. Each
                  Pass has a specified notional value and will enable you to
                  join a Contest that has an Entry Fee of an equal or lower
                  value. You will not be allowed to use your Pass for a Cash
                  Based Format or a MTT. The details of any Passes made
                  available to you will be reflected in your Remittance
                  Portfolio.
                </li>
                <li>
                  You may also earn Passes if you refer the App to third parties
                  who register and create a Player Profile on the App, and remit
                  qualifying minimum amounts towards playing games on the App,
                  as may be specified by us from time to time. To avail Passes
                  pursuant to your referrals, the referred Player must provide
                  us with your phone number.
                </li>
                <li>
                  All Passes have a fixed validity period and will expire if you
                  do not utilize them before their expiry.
                </li>
                <li>
                  When you enter a Contest using a Pass and are awarded
                  Winnings, basis the relevant winning criteria, the value of
                  such Pass will be deducted from such Winnings. If you lose the
                  Contest, then the value of the Pass is to our account. By way
                  of illustration:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      <em>Each pass has:</em>
                      <ol style={{ listStyleType: 'lower-roman' }}>
                        <li>
                          <strong>
                            <em>Maximum value</em>
                          </strong>
                          <em>
                            . Maximum value. This is the maximum value of a
                            single pass to join a game. To elaborate, if your
                            pass has a maximum value of 10 (ten), you can join
                            contests with entry fees of upto INR. 10 (Indian
                            Rupees Ten). For example, an INR. 5 (Indian Rupees
                            Five) or an INR 10 (Ten) contest, but you cannot use
                            the pass to join an INR 15 (Indian Rupees Fifteen)
                            or INR 20 (Indian Rupees Twenty) contest.
                          </em>
                        </li>
                        <li>
                          <strong>
                            <em>Pass fee.</em>
                          </strong>
                          <em>
                            Pass fee is the charge that is deducted from your
                            Winnings if you use a pass to join a room. A pass
                            will give you free access to a room, however, Mega
                            puts up the amount on your behalf. If you lose a
                            game then there is no charge applicable, however,
                            Mega will deduct the pass fee (room joining charge)
                            from your Winnings. Pass fee may vary for each pass
                            and the fee will be mentioned on the pass at the
                            time of exercise.
                          </em>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <p>
                        <em>
                          To help you understand better, if you use a pass of
                          maximum value of 10 (ten) and pass fee of 25% (twenty
                          five percent) and you join a 10 (ten) Gem contest of
                          maximum 2 (two) players.
                        </em>
                      </p>
                      <p>
                        <em>Total game pool - 10 + 10 = 20</em>
                      </p>
                      <p>
                        <em>Winning amount - Rs 14 (Rupees Fourteen)</em>
                      </p>
                      <p>
                        <em>
                          Pass fee applicable = 25% X 10(Room joining cost) =
                          2.5
                        </em>
                      </p>
                      <p>
                        <em>
                          Final game Winnings = 14(game Winnings) - 2.5(Pass
                          fee) = 11.5
                        </em>
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <h2>Refund of Entry Fee or BUY-IN</h2>
              <ol className="decimal-counter o2">
                <li>
                  In the event, you are unable to participate in a Contest after
                  paying the Entry Fee or the Buy-In, due to (a) the Contest
                  being cancelled by us; (b) requisite number of Players being
                  unavailable; or (c) our failure to match you with Players,
                  then the amount paid by you as Entry Fee or Buy-In will be
                  refunded to your Remittance Portfolio.
                </li>
                <li>
                  In the event, you are unable to participate in a Contest after
                  paying the Entry Fee or Buy-In due to (a) your failure to play
                  a Game during the period the Contest is running; (b) losing
                  internet or data connectivity; (c) the App crashing on your
                  Compatible Device (as defined below); or (d) any other reason
                  which has not been specified in paragraph 10.2, you will
                  forfeit the amount paid as Entry Fee and shall not be entitled
                  to any refund or compensation.
                </li>
              </ol>
            </li>

            <li>
              <h2>Winnings</h2>
              <ol className="decimal-counter o2">
                <li>
                  Each Contest has different criteria for winning and has
                  corresponding Winnings. Details of the winning criteria and
                  the Winnings will be provided to you at the time of joining
                  the Contest.
                </li>
                <li>
                  Winnings may include but may not be limited to monetary prizes
                  (which may either be fixed or variable based on the number of
                  Players participating in the particular Contest), or other
                  non-cash based awards such as products, discount vouchers or
                  other intangible awards.
                </li>
                <li>
                  The winning criteria as well as the Winnings will be decided
                  by us on our sole discretion.
                </li>
                <li>
                  In the event you win a Contest basis the relevant winning
                  criteria, you may win monetary or non-monetary Winnings. Only
                  monetary Winnings will reflect in your Winnings Portfolio.
                </li>
                <li>
                  In the event, you win monetary Winnings, you are entitled to
                  (a) utilise the amount to play Games on the App; or (b)
                  withdraw the amount in your bank account or wallet associated
                  with the App (“<strong>Designated Account</strong>”), subject
                  to you fulfilling all conditions specified in these Terms, to
                  our satisfaction.
                </li>
                <li>
                  Non-monetary Winnings may be claimed by following the
                  instructions provided in the App.
                </li>
                <li>
                  All Winnings will be credited to the Player subject to tax
                  deduction at source in compliance with applicable law.
                </li>
                <p>
                  <strong>
                    <em>Instant Format: No-show in a Contest</em>
                  </strong>
                </p>
                <li>
                  In the event you have joined a Contest before it commenced and
                  failed to play the Game during the period in which the Contest
                  is running and active, your Entry Fee, will be forfeited and
                  you will not be entitled to any refund or compensation for the
                  same.
                </li>
                <li>
                  Further, in the event, you participate in more than one
                  Contest, all of which are running simultaneously, and are
                  unable to play the relevant Game for any of the Contests when
                  they are running, then you will forfeit the Entry Fee you have
                  paid for such Contests.
                </li>
                <li>
                  Furthermore, you will not be entitled to any Winnings in the
                  event you fail to play the relevant Game after joining a
                  Contest.
                </li>
              </ol>
            </li>

            <li>
              <h2>Payments and Transfer of Monetary Winnings</h2>
              <ol className="decimal-counter o2">
                <p>
                  <strong>
                    <em>Payments</em>
                  </strong>
                </p>
                <li>
                  You may remit to your Remittance Portfolio using any of the
                  options available on the App including (a) through wallets you
                  link with the App; (b) debit/credit cards; (c) Unified Payment
                  Interface; (d) net banking; and (e) such other payment options
                  as are made available on the App from time to time.
                </li>
                <li>
                  You understand that these payment gateways are managed solely
                  by third party service providers and therefore your use of
                  such payment gateways will be governed by the terms and
                  conditions of such third-party service providers. You agree
                  that your use of a third-party payment gateway is at your sole
                  option and risk.
                </li>
                <li>
                  The value reflecting in your Remittance Portfolio, Winnings
                  Portfolio or the value of your Winnings from a Contest may be
                  varied by us for any reason including due to (a) change in
                  applicable tax rates; (b) change in applicable laws; or (c)
                  any other relevant changes that are applied across the App.
                  The change in the value reflected in your Remittance Portfolio
                  and/or Winnings Portfolio will only be effective after it has
                  been notified on the App.
                </li>
                <p>
                  <strong>
                    <em>Transfer of Monetary Winnings</em>
                  </strong>
                </p>
                <li>
                  You may withdraw your monetary Winnings from your Winnings
                  Portfolio to your Designated Account, subject to the following
                  conditions:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      The monetary Winnings in your Winnings Portfolio is more
                      than the minimum required balance of Rs. 5 (Rupees One
                      Hundred and Fifty only). This minimum required balance
                      amount may be revised from time to time;
                    </li>
                    <li>
                      You have completed the KYC requirements in accordance with
                      these Terms and to our complete satisfaction;
                    </li>
                    <li>
                      You may withdraw according to the maximum limit per day
                      which will be reflected and revised on the App from time
                      to time; and
                    </li>
                    <li>
                      You will be responsible for payment of processing charges,
                      taxes and complying with any applicable laws in relation
                      to any monetary Winnings earned by you;
                    </li>
                  </ol>
                </li>
                <p>
                  <strong>Withdrawal from your Remittance Portfolio</strong>
                </p>
                <li>
                  You hereby provide your consent to us to hold the amounts in
                  the Remittance Portfolio on your behalf and we will not have
                  any right rights over the amounts in the Remittance Portfolio,
                  other than as set out in these Terms. You may also withdraw
                  the amount from your Remittance Portfolio to your Designated
                  Account, subject to the following conditions:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      You have completed the KYC requirements in accordance with
                      these Terms and to our complete satisfaction;
                    </li>
                    <li>
                      You may withdraw according to the maximum limit per day
                      which will be reflected and revised on the App from time
                      to time ; and
                    </li>
                    <li>
                      You will be responsible for payment of processing charges,
                      taxes and complying with any applicable laws in relation
                      to the withdrawal;
                    </li>
                  </ol>
                </li>
                <p>
                  <strong>
                    <em>KYC</em>
                  </strong>
                </p>
                <li>
                  Since the App involves real-money gaming, we, in line with
                  industry practice and to comply with applicable laws and our
                  internal policies, mandatorily require you to complete KYC and
                  provide proof of identity.
                </li>
                <li>
                  You will be required to enter your KYC details and upload
                  necessary documents in order to complete your KYC. You will be
                  able to withdraw your Winnings only after you have completed
                  your KYC.
                </li>
                <li>
                  Pursuant to the completion of your KYC, you will only be able
                  to transfer your monetary Winnings to your Designated Account
                  for which you have completed KYC with us.
                </li>
                <li>
                  After your Winnings exceed Rs.500 (Rupees Five Hundred only),
                  you will not be able to access any Games, claim your Winnings
                  or carry out any other activity on the App until your KYC
                  documents have been uploaded to our satisfaction. Failure to
                  upload the same within the time period provided above will
                  lead to automatic closure of your Player Profile.
                </li>
                <li>
                  Upon closure of your Player Profile because of your failure to
                  fulfil the KYC requirements in accordance with these Terms, we
                  will not be liable to pay the Winnings to you and you will not
                  be entitled to make any claims against us in this regard.
                </li>
              </ol>
            </li>

            <li>
              <h2>Community</h2>
              <p>
                The App may enable you to join gaming communities and groups
                (collectively, “<strong>Community Pages</strong>”) on third
                party social media platforms. Access and use of the Community
                Pages are subject to the Terms and other policies of such
                third-party platforms.
              </p>
            </li>

            <li>
              <h2>Your Information</h2>
              <ol className="decimal-counter o2">
                <li>
                  You will ensure that the information provided by you is
                  accurate, true and up to date at all times.
                </li>
                <li>
                  You undertake that should your information change at any time
                  during your use of the App, you shall update your information
                  to ensure it remains true, current, complete and accurate at
                  all times.
                </li>
                <li>
                  In the event of any discrepancy with respect to any of the
                  information submitted by you:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      You will not be permitted to participate in any of the
                      Games; and
                    </li>
                    <li>
                      In case you are entitled to any Winnings, you will not be
                      eligible for being awarded such Winnings.
                    </li>
                  </ol>
                </li>
                <li>
                  Further, if at any time, including after disbursement of your
                  Winnings, any information submitted by you is found to be
                  incorrect, false, or otherwise misrepresented or misleading,
                  you shall be liable to refund all amounts received by you form
                  us.
                </li>
                <li>
                  By providing us with the information, you represent and
                  warrant that the contact number supplied by you is not part of
                  any “do not call” registry or its equivalent, anywhere in the
                  world and that we may use the information to send you
                  communication, notices or alerts that are transactional,
                  informational and promotional in nature, from time to time.
                </li>
                <li>
                  You expressly grant us the right to monitor your online
                  activities in relation to the App including but not limited to
                  logging of your IP address to verify your geographical
                  location.
                </li>
                <li>
                  Furthermore, you provide your consent to us to display all or
                  part of your Player Profile which may include your name,
                  photograph (if any), and your total score, on the App’s
                  leadership board. You also provide your consent to us to use
                  either the details provided by you for your Player Profile or
                  all or part of your Player Profile for the purposes of
                  marketing, promotions and/or other such related purposes on
                  print, digital, social media and/or other marketing channels.
                </li>
              </ol>
            </li>

            <li>
              <h2>Termination</h2>
              <ol className="decimal-counter o2">
                <p>
                  <strong>
                    <em>Termination by you</em>
                  </strong>
                </p>
                <li>
                  You may delete your Player Profile and uninstall the App at
                  any time to discontinue your participation in the Contests.
                </li>
                <li>
                  We will not be liable to return the amount that was available
                  in the Winnings Portfolio and Winnings associated with the
                  Profile and the same will be transferred to Mega’s account if:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      You have not withdrawn all Winnings in your Winnings
                      Portfolio within a period of 30 (thirty) days from
                      uninstalling the App or foreclosure of the App. Mega will
                      periodically reach send out communications to you through
                      the contact details registered with us, reminding you to
                      withdraw your Winnings, after you uninstall the App or
                      your Player Profile is foreclosed;
                    </li>
                    <li>
                      You have created a Player Profile, which is not in
                      accordance with the Terms specifically set out in 1.5; or
                    </li>
                    <li>
                      You have not completed your KYC as set out in 10.8 above.
                    </li>
                  </ol>
                  <p>
                    We will not be liable for any claims against us made by you,
                    your parents or guardians in this regard.
                  </p>
                </li>
                <p>
                  <strong>
                    <em>Foreclosure owing to inactivity</em>
                  </strong>
                </p>
                <li>
                  If your Player Profile is inactive for more than 30 (thirty)
                  days, we reserve the right to terminate your Player Profile
                  after deducting foreclosure charges that may amount up to Rs.
                  800 (Rupees Eight Hundred only) (“
                  <strong>Foreclosure Charges</strong>”) from your Winnings
                  Portfolio.
                </li>
                <li>
                  If within the first 6 months of signing up, the player hasn't
                  played any cash games, then their account will be foreclosed.
                  This means that we will empty their app wallets of any deposit
                  / bonus money.
                </li>
                <li>
                  You will be entitled to withdraw the balance Winnings in your
                  Winnings Portfolio within a period of 30 (thirty) days from
                  the date of foreclosure of your Player Profile. All balance
                  Winnings in your Winnings Portfolio will revert to Mega’s
                  account in accordance with 14.3 above to the extent not
                  withdrawn.
                </li>
                <li>
                  Mega reserves the right to modify the Foreclosure Charges at
                  any time without providing any prior notice.
                </li>
                <li>
                  It is clarified that uninstalling the App does not result in
                  termination of your Player Profile.
                </li>
                <p>
                  <strong>
                    <em>Termination by us</em>
                  </strong>
                </p>
                <li>
                  We have the right to (a) suspend your access to the App and/or
                  any of the Games; (b) terminate your Player Profile; (c) stop
                  the refund and/or block any unused funds in the Player’s
                  Profile; and/or (d) return or forfeit any Winnings as the case
                  maybe, forthwith, at any time:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      Forfeit the amounts In the event of inactivity for a
                      continuous period of 335 days;
                    </li>
                    <li>For any purpose, without notice;</li>
                    <li>
                      If you have been or we have reasonable grounds to be
                      believe that you have breached any of our Policies, or
                      been convicted of an offence which involves moral
                      turpitude or any offence under Indian laws;
                    </li>
                    <li>
                      If you have registered or are accessing the App from any
                      jurisdiction (including Indian states) where playing such
                      Games are prohibited; or if it comes to our notice that
                      conduct of any Games or the App is prohibited in a
                      particular jurisdiction (including Indian states); or
                    </li>
                    <li>
                      If we believe that you are indulging in unfair or
                      irresponsible gaming.
                    </li>
                  </ol>
                </li>
                <li>
                  You agree that the availability of the App and/or any of the
                  Games and Your ability to access the App and/or any of the
                  Games is subject to our sole discretion. You acknowledge that
                  the App and/or any of the Games may not be available at all
                  times.
                </li>
                <li>
                  If a player is involved in a duplicitous transaction during
                  any of their deposits, then their account will be blocked and
                  we will empty their app wallets of any deposit / bonus money.
                </li>
                <li>
                  We have the right to suspend, withdraw or cease any Contest,
                  Game or the entire App at any time without providing any
                  notice or being liable to you in any manner whatsoever. In the
                  event we are required to withdraw or cease any Contest, Game
                  or the entire App to comply with any regulatory or statutory
                  directions, we may, in our sole discretion, refund all amounts
                  present in your Winnings Portfolio or Remittance Portfolio to
                  your Designated Account, after deducting applicable charges
                  and taxes.
                </li>
              </ol>
            </li>

            <li>
              <h2>Some Game Specific Terms</h2>
              <ol className="decimal-counter o2">
                <li>
                  In relation to Trivia Games, we have the right to decide the
                  questions and the answer to each question to be used in the
                  Game, in our sole discretion. We shall have the sole authority
                  to decide the validity of any answer and do not guarantee that
                  any questions and/or the corresponding answers will be
                  complete or accurate in any manner. We shall not be liable for
                  any loss incurred by you due to any incorrect questions and/or
                  answers or any discrepancy or errors in the same.
                </li>
                <li>
                  We also have the sole discretion to decide the points,
                  weightage attached, and time provided to respond, to each of
                  the questions in any of the Games.
                </li>
                <li>
                  We may introduce new features in any of the Games including
                  introducing the option to avail hints, increase or decrease
                  time or provide other alternatives in relation to existing
                  Games. We may also alter the format of the existing Games or
                  introduce new Games, at our sole discretion.
                </li>
              </ol>
            </li>

            <li>
              <h2>Responsible Gaming</h2>
              <ol className="decimal-counter o2">
                <li>
                  We offer Games on the App for entertainment only and are
                  committed to promoting fair play and responsible gaming.
                </li>
                <li>
                  To continue enjoying the App and the Games offered on it
                  without such enjoyment resulting in any adverse personal,
                  financial or social consequences, it is imperative that you
                  play responsibly that is you minimise your indulgence or do
                  not indulge in game-play beyond your means or for excessive
                  periods of time.
                </li>
                <li>
                  By creating a Player Profile and playing Games on the App, you
                  agree that you will be engage in responsible gaming by:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      Understanding and acknowledging that the Games are for
                      entertainment only and carry with them the risk of losing;
                    </li>
                    <li>
                      Striking a balance between the time you spend on the App
                      playing Games and other aspects of your life such as
                      focusing on your job or spending time with your family;
                    </li>
                    <li>
                      Ensuring that you play Games in a fair manner without
                      resorting to any means of unfairness or cheating;
                    </li>
                    <li>Playing for entertainment and not for making money;</li>
                    <li>
                      Playing only with your own money which you can afford to
                      lose;
                    </li>
                    <li>
                      Deciding in advance how much money and time you will spend
                      playing Games on the App for a particular session and
                      sticking to that limit regardless of your performance;
                    </li>
                    <li>
                      Not playing Games on the App when you are upset, tired, or
                      depressed as it is difficult to make good decisions when
                      you are feeling low;
                    </li>
                    <li>
                      Completing all your important tasks before playing Games
                      on the App so that you can play with a free and calm mind;
                    </li>
                    <li>
                      Avoiding using the entire amount available in your
                      Remittance Portfolio and Winnings Portfolio in a single
                      session of playing; and
                    </li>
                    <li>
                      Taking time outs and breaks regularly regardless of your
                      performance in the Games and especially if you have lost
                      continuously.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <h2>Minimum Technical Requirements</h2>
              <ol className="decimal-counter o2">
                <li>
                  In order to ensure optimal use of the App, you will need to
                  access the App on an android phone with a RAM of at least 2 GB
                  and a 4G internet connection (“
                  <strong>Compatible Device</strong>”). We will not be
                  responsible for sub-optimal results because of device and
                  internet connections.
                </li>
                <li>
                  You acknowledge that these Compatible Devices may be
                  maintained and controlled by third party providers and
                  therefore, platforms, software and/or devices that qualify at
                  present as Compatible Devices may cease to be Compatible
                  Devices in the future. Should your platform, software and/or
                  device cease to be compatible, you agree that it is your sole
                  responsibility to procure a Compatible Device to be able to
                  continue using the App and we carry no liability for the same.
                  Your use of third-party Compatible Devices are governed by the
                  terms and conditions of such third parties.
                </li>
                <li>
                  You agree that the quality of the App and/or Games may vary
                  depending on a variety of parameters such as your location,
                  bandwidth, Compatible Device, Internet availability and speed,
                  etc.
                </li>
              </ol>
            </li>

            <li>
              <h2>User Obligations</h2>
              <ol className="decimal-counter o2">
                <li>
                  The App is to be used only for their intended purpose in a
                  bona fide manner, and is not to be misused in any manner,
                  including for undertaking fraudulent transactions,
                  impersonation or any such illegal activity.
                </li>
                <li>
                  You warrant that you are using the App on your own initiative
                  and that you are responsible for compliance with any and all
                  applicable laws in relation to your use of the App.
                </li>
                <li>
                  You shall not:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      Use the App for any purpose that is unlawful or prohibited
                      by the Terms;
                    </li>
                    <li>
                      Circumvent in any manner any geotagging or geographical
                      restrictions that may be imposed on the use of the App and
                      the playing of any of the Games;
                    </li>
                    <li>
                      Impose an unreasonable or disproportionately large load on
                      our infrastructure;
                    </li>
                    <li>
                      Attempt to or gain unauthorized access to any portion or
                      feature of the App including accounts, or any other
                      systems or networks connected to the App or to any server,
                      computer, network, or to any of the services by hacking,
                      password “mining” or any other illegitimate means;
                    </li>
                    <li>
                      Directly or indirectly solicit the account information of
                      other users or access or try to access any account which
                      does not belong to you;
                    </li>
                    <li>
                      Probe, scan or test the vulnerability of the App or any
                      network connected to the App or the Games or breach the
                      security or authentication measures on the App or any
                      network connected to the App;
                    </li>
                    <li>Extract data from the App;</li>
                    <li>
                      Attempt to circumvent the regular operation of the App, or
                      reduce the fees or consideration that we may derive from
                      the App by any means including by creating multiple
                      accounts, redirecting traffic or following other
                      fraudulent or deceptive practices or undertake any actions
                      to undermine the integrity of the data, systems or
                      networks used by us in the App or gain unauthorized access
                      to such data, systems or networks;
                    </li>
                    <li>
                      Collect any user information through automated means,
                      including but not limited to bots, robots, spiders and
                      scrapers without our prior written permission;
                    </li>
                    <li>
                      Reproduce, duplicate, copy, sell, visit, distribute, use
                      or otherwise exploit the App for any commercial purposes
                      except as in accordance with the Policies;
                    </li>
                    <li>
                      Introduce any computer viruses or other destructive
                      devices and codes that have the effect of damaging,
                      interfering with, intercepting or expropriating any
                      software or hardware system, data or personal information;
                    </li>
                    <li>
                      Facilitate or encourage any violation of these Terms or
                      our other Policies, including the Privacy Policy, as
                      amended from time to time;
                    </li>
                    <li>
                      Make any statement(s) or comment(s) on the App which
                      is/are inaccurate, false, unfair or defamatory to us or
                      other users or which violates the legal right of others;
                      and
                    </li>
                    <li>
                      Engage in any activities that would otherwise create any
                      liability for us or our affiliates.
                    </li>
                  </ol>
                </li>
                <li>
                  Further, you agree not to upload, host, display, publish,
                  share or otherwise make available on the App any content or
                  information that:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      Belongs to another person and to which you do not have any
                      right to;
                    </li>
                    <li>
                      Contains any content which is non-compliant with the
                      Information Technology Act, 2000, rules, regulations, and
                      guidelines made thereunder, including Rule 3 of The
                      Information Technology (Intermediaries Guidelines) Rules,
                      2011, Terms or Privacy Policy, as amended or re-enacted
                      from time to time; or
                    </li>
                    <li>
                      Infringes any intellectual property rights of any third
                      party.
                    </li>
                  </ol>
                </li>
                <li>
                  You agree to be fair, accurate and non-disparaging while
                  leaving comments, feedback, testimonials and reviews on or
                  about the App and/or any of the Games.
                </li>
              </ol>
            </li>

            <li>
              <h2>Intellectual Property</h2>
              <ol className="decimal-counter o2">
                <li>
                  The name ‘Mega’ and all corresponding marks, logos, designs,
                  trade dress, trademarks that we use in connection with the App
                  are our intellectual property and cannot be used by you for
                  any purposes not specified in these Terms.
                </li>
                <li>
                  All intellectual property in the App, Games and in the
                  proprietary material, content and information made available
                  on the App including the graphics, images, photographs, logos,
                  trademarks, the appearance, organisation and layout of the App
                  and the underlying software code belongs to us.
                </li>
                <li>
                  You must not copy, modify, alter, decompile, reverse engineer,
                  publish, broadcast, distribute, sell or transfer (whether in
                  whole or in part) any such material, except as expressly
                  permitted in these Terms.
                </li>
                <li>
                  If you believe any content on the App infringes your
                  intellectual property rights, you may notify us by contacting
                  us on the details provided below.
                </li>
              </ol>
            </li>

            <li>
              <h2>Breach of Terms</h2>
              <p>
                If you have, or we have reasonable grounds to believe that you
                have violated these Terms, or that your use conflicts or
                interferes with our reputation, interest or might subject us to
                unfavourable legal or regulatory action in any way, we can
                indefinitely suspend or terminate your access to the App at any
                time, and report such action to relevant authorities. We reserve
                the right to take recourse to all available remedies under these
                Terms, applicable law or equity in furtherance of the above.
              </p>
            </li>

            <li>
              <h2>Indemnity</h2>
              <p>
                You agree to defend, indemnify and hold harmless us, our
                affiliates, directors, officers, employees and agents from and
                against any and all claims, damages, obligations, losses,
                liabilities, costs or debt, and expenses (including but not
                limited to attorneys’ fees) arising from (i) your use of and
                access to the App; or (ii) Your violation of any term of these
                Terms; or (iii) Your violation of any third party right,
                including without limitation any privacy, publicity or
                intellectual property right; (iv) Your breach of applicable
                law(s); (v) any unauthorized, wrongful, unlawful or illegal use
                of your Player Profile by a third party with or without your
                consent.
              </p>
            </li>

            <li>
              <h2>Disclaimer of Warranties and Limitation of Liability</h2>
              <ol className="decimal-counter o2">
                <li>
                  YOU AGREE AND ACKNOWLEDGE THAT THE APP AND THE GAMES ARE
                  PROVIDED ‘AS-IS’ WITHOUT WARRANTIES OF ANY KIND, EITHER
                  EXPRESS OR IMPLIED INCLUDING THOSE OF TITLE, FITNESS FOR
                  PURPOSE AND MERCHANTABILITY, COMPLETENESS, AVAILABILITY,
                  SECURITY, COMPATIBILITY OR NON-INFRINGEMENT, TO THE FULLEST
                  EXTENT PERMITTED BY APPLICABLE LAW. YOU ACKNOWLEDGE THAT THE
                  FUNCTIONING OF THE APP IS NOT ERROR FREE, UNINTERRUPTED OR
                  FREE OF VIRUSES AND OTHER HARMFUL COMPONENTS.
                </li>
                <li>
                  While there are no pre-set limits on your remittance to play
                  Games on the App, you are required to use your discretion and
                  judgment for remitting and maintaining your monetary Winnings,
                  as reflected in the Winnings Portfolio, on the App We do not
                  guarantee the safety and security of the amounts reflected in
                  your Remittance Portfolio or Winnings Portfolio and these are
                  subject to routine commercial risks.
                </li>
                <li>
                  We shall not be liable for any loss or damage or failure to
                  comply with or delay in complying with our obligations under
                  these Terms which is caused directly or indirectly by any
                  event or circumstances beyond our reasonable control including
                  due to system failure, network issues, technical snags or loss
                  of data due to any reasons whatsoever including any of the
                  preceding reasons, act of God, floods, epidemics, quarantine,
                  riot or war.
                </li>
                <li>
                  In no event shall we be liable for any special, punitive,
                  incidental, indirect or consequential damages, including but
                  not limited to any loss of profits, revenue, business or data
                  of any kind in connection with the App, these Terms, your
                  inability to use the App, or your interactions with other
                  users on the App, whether under contract, tort, or otherwise,
                  including for negligence, even if we has been informed in
                  advance of the possibility of such damages.
                </li>
                <li>
                  Notwithstanding any of the foregoing provisions, the aggregate
                  liability of Mega, its employees, agents, affiliates,
                  representatives or anyone acting on its behalf with respect to
                  each user of the App for all claims arising from the access
                  to, or use of, the App shall be limited to the price you have
                  paid for the Pack in respect of which you have a claim.
                </li>
                <li>
                  The limitations and exclusions of liability to you under the
                  Terms shall apply to the maximum extent permitted by law and
                  shall apply whether or not we have been advised of, or should
                  have been aware of, the possibility of any such losses
                  arising.
                </li>
              </ol>
            </li>

            <li>
              <h2>Availability of App</h2>
              <ol className="decimal-counter o2">
                <li>
                  We do not warrant that:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      The App will be constantly available or available at all.
                      We shall have no liability to you for any interruption or
                      delay in access to the App or the Games played through it,
                      irrespective of the cause;
                    </li>
                    <li>
                      The information on the App is complete, true, accurate or
                      non-misleading;
                    </li>
                    <li>
                      The App is secure or free of viruses, Trojans or other
                      malware; or
                    </li>
                    <li>
                      The contents of the App do not infringe any intellectual
                      property rights.
                    </li>
                  </ol>
                </li>
                <li>
                  The access to the App may also be occasionally suspended or
                  restricted or certain features may be unavailable to allow for
                  scheduled maintenance, repairs or upgrading the App at any
                  time without prior notice.
                </li>
                <li>
                  We reserve the right to modify the App, our technology and
                  Games offered of the App, from time to time.
                </li>
              </ol>
            </li>

            <li>
              <h2>Information and Privacy Policy</h2>
              <ol className="decimal-counter o2">
                <li>
                  We reserve the right to store and retain any and all
                  information provided by you including the Games played,
                  Winnings won, Player Profile details and details of the
                  transactions conducted on the App for our business purposes as
                  well as to comply with applicable laws.
                </li>
                <li>
                  Any personal information you supply to us when you use the App
                  will be used in accordance with our{' '}
                  <a href="/privacy-policy">Privacy Policy</a>.
                </li>
              </ol>
            </li>

            <li>
              <h2>Third party links</h2>
              <ol className="decimal-counter o2">
                <li>
                  The App may include hyperlinks to various external websites,
                  applications, content, or resources and may also include
                  advertisements (“<strong>Third-Party Links</strong>”). We have
                  no control over such Third-Party Links present on the App,
                  which are provided by persons or companies other than us. You
                  acknowledge and agree that we are not responsible for any
                  collection or disclosure of your information by any external
                  sites, applications, companies or persons thereof. The
                  presence of any Third-Party Links on the App cannot be
                  construed as a recommendation, endorsement or solicitation of
                  any material or content present on such Third-Party Links, or
                  any other material on or available through such Third-Party
                  Links.
                </li>
                <li>
                  You further acknowledge and agree that we are not liable for
                  any loss or damage which may be incurred by you as a result of
                  the collection and/or disclosure of your information through
                  such Third-Party Links or as a result of any reliance placed
                  by you on the completeness, accuracy or existence of any
                  advertising or other materials on, or available through such
                  Third-Party Links. This will include all transactions and
                  information transmitted on such Third-Party Links between you
                  and any third-party sites or applications or resources. All
                  transactions undertaken by you on the Third-Party Links are
                  strictly bipartite. We shall not be liable for any disputes
                  arising from, or in connection with, such transactions between
                  you and such third parties.
                </li>
                <li>
                  Such third-party websites, and external applications or
                  resources, accessible using the Third-Party Links may have
                  their own privacy policies governing the collection, storage,
                  retention and disclosure of your information that you may be
                  subject to. We recommend that you exercise reasonable
                  diligence, as you would in traditional offline channels and
                  practice judgment and common sense before committing to any
                  transaction or exchange of information, including but not
                  limited to reviewing the third-party website or application’s
                  privacy policy.
                </li>
              </ol>
            </li>

            <li>
              <h2>Severability and Waiver</h2>
              <p>
                If any provisions of these Terms are found to be invalid by any
                court having competent jurisdiction, the invalidity of such
                provision shall not affect the validity of the remaining
                provisions of these, which shall remain in full force and
                effect. No waiver of any term of these Terms shall be permitted.
              </p>
            </li>

            <li>
              <h2>Governing law and jurisdiction</h2>
              <p>
                These Terms shall be governed by and interpreted and construed
                in accordance with the laws of India as applicable between
                resident Indians. Subject to the dispute resolution term below,
                any disputes pertaining to the App shall be subject to the
                exclusive jurisdiction of the appropriate courts of Bengaluru,
                India.
              </p>
            </li>

            <li>
              <h2>Dispute Resolution</h2>
              <p>
                All disputes arising from or in connection with these Terms
                shall be settled amicably between the parties within 30 (thirty)
                days, failing which it shall be referred to and finally settled
                by arbitration in accordance with the Indian Arbitration and
                Conciliation Act, 1996 as amended from time to time, presided
                over by a sole arbitrator appointed mutually appointed by the
                Parties failing which it shall be appointed by the High Court of
                Bengaluru. Judgment upon the arbitral award may be entered in
                any court having jurisdiction over the Parties or their assets.
                The arbitration proceedings shall be held at Bengaluru. The
                arbitration proceedings shall be conducted in the English
                language.
              </p>
            </li>

            <li>
              <h2>Contact us</h2>
              <ol className="decimal-counter o2">
                <li>
                  Please contact us if you have any questions or comments on the
                  App, these Terms and any of the Policies (including all
                  inquiries related to copyright infringement) regarding the
                  App.
                </li>
                <li>
                  This document is an electronic record and is generated by a
                  computer system and does not require any physical or digital
                  signatures.
                </li>
              </ol>
            </li>
          </Container>
        </div>
      </ol>
      {/* </Container> */}
    </div>
  )
}

export default TermsAndConditions
